






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { VsCheckboxSizeEnum } from '@advision/vision/src/components/VsCheckboxGroup/types'

export interface VsCheckboxParmas {
    inputValue: ''
    text: ''
    disabled: ''
}

@Component({
    name: 'VsCheckboxGroup',
})
export default class VsCheckboxGroup extends Vue {
    @Prop({
        required: false,
        default: false,
    }) disabled?: boolean

    @Prop({
        required: false,
        default: false,
    }) error?: boolean

    @Prop({
        required: false,
        default: '',
    }) errorMessage?: string

    @Prop({
        required: false,
        default: '',
    }) caption?: string

    @Prop({
        required: false,
        default: '',
    }) label?: string

    @Prop({
        required: false,
        default: false,
    }) labelHidden?: boolean

    @Prop({
        required: false,
        default: VsCheckboxSizeEnum.medium,
    }) size?: string

    @Prop({
        required: false,
        default: undefined,
    }) value?: any

    @Prop({
        required: false,
        default: () => [],
    }) checkboxes?: any[]

    private emitInput (value: any[]) {
        if (this.disabled) return
        this.$emit('input', value)
    }

    get outerDynamicClasses () {
        return {
            label: {
                'vs-mb-2': this.size === VsCheckboxSizeEnum.medium || this.size === VsCheckboxSizeEnum.large,
                'vs-block': true,
                'vs-hidden': this.labelHidden,
            },
            caption: {
                'vs-mt-2': this.size === VsCheckboxSizeEnum.medium || this.size === VsCheckboxSizeEnum.large,
                'vs-body-medium': this.size === VsCheckboxSizeEnum.large,
                'vs-body-small': this.size === VsCheckboxSizeEnum.medium,
                'vs-text-grey-800': !this.error,
                'vs-text-alert-500': this.error,
            },
            global: {
                'vs-checkbox-group': true,
                'vs-w-fit-content': true,
            },
        }
    }
}
