var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.outerDynamicClasses.global},[_c('VsLabel',{class:_vm.outerDynamicClasses.label,attrs:{"size":_vm.size}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"vs-flex vs-gap-4 vs-items-center"},[_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"labelHidden":true,"value":_vm.hours,"size":_vm.size,"clearable":true,"filterable":true,"error":_vm.error,"options":[{
                label: 'Ore',
                childs: this.getCounterOptions(24)
            }]},on:{"input":_vm.changeHour}}),_c('span',{staticClass:"vs-flex-none"},[_vm._v(" : ")]),_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"labelHidden":true,"value":_vm.minutes,"size":_vm.size,"clearable":true,"filterable":true,"error":_vm.error,"options":[{
                label: 'Minuti',
                childs: this.getCounterOptions(60)
            }]},on:{"input":_vm.changeMinutes}}),_c('span',{staticClass:"vs-flex-none"},[_vm._v(" : ")]),_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"labelHidden":true,"value":_vm.seconds,"size":_vm.size,"clearable":true,"filterable":true,"error":_vm.error,"options":[{
                label: 'Secondi',
                childs: this.getCounterOptions(60)
            }]},on:{"input":_vm.changeSeconds}})],1),(_vm.caption || (_vm.error && _vm.errorMessage))?_c('vs-caption',{class:_vm.outerDynamicClasses.caption},[(!_vm.error)?_c('span',[_vm._v(_vm._s(_vm.caption))]):_vm._e(),(_vm.errorMessage && _vm.error)?_c('span',[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }