




































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsTimeInput',
})
export default class VsTimeInput extends Vue {
    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) disabled?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) error?: boolean

    @Prop({
        required: false,
        default: '',
    }) errorMessage?: string

    @Prop({
        required: false,
        default: '',
    }) caption?: string

    @Prop({
        required: false,
        default: '',
    }) label?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) labelHidden?: boolean

    @Prop({
        required: false,
        default: 'medium',
    }) size?: string

    @Prop({
        required: false,
        default: () => {
            return {
                startDate: null,
                endDate: null,
            }
        },
    }) value!: any

    private emitValue (val: string) {
        this.$emit('input', val)
    }

    private changeHour (hour: string) {
        if (!hour && !this.minutes && !this.seconds) return this.emitValue('')
        this.emitValue(`${hour || ''}:${this.minutes}:${this.seconds}`)
    }

    private changeMinutes (minutes: string) {
        if (!this.hours && !minutes && !this.seconds) return this.emitValue('')
        this.emitValue(`${this.hours}:${minutes || ''}:${this.seconds}`)
    }

    private changeSeconds (seconds: string) {
        if (!this.hours && !this.minutes && !seconds) return this.emitValue('')
        this.emitValue(`${this.hours}:${this.minutes}:${seconds || ''}`)
    }

    get hours () {
        return this.value ? this.value.split(':')[0] : ''
    }

    get minutes () {
        return this.value ? this.value.split(':')[1] : ''
    }

    get seconds () {
        return this.value ? this.value.split(':')[2] : ''
    }

    get outerDynamicClasses () {
        return {
            label: {
                'vs-mb-2': this.size === 'medium' || this.size === 'large',
                'vs-block': true,
                'vs-hidden': this.labelHidden,
            },
            caption: {
                'vs-mt-2': this.size === 'medium' || this.size === 'large',
                'vs-body-medium': this.size === 'large',
                'vs-body-small': this.size === 'medium',
                'vs-text-grey-800': !this.error,
                'vs-text-alert-500': this.error,
            },
            global: {
                'vs-time-input': true,
            },
        }
    }

    private getCounterOptions (quantity: number): any[] {
        const options = []
        for (let i = 0; i < quantity; i++) {
            const prepend = i < 10 ? '0' : ''
            options.push({
                label: prepend + i,
                value: prepend + i,
            })
        }
        return options
    }
}
